<!--Unused view -->
<template>
  <v-container class="archive pa-0 ma-0" fluid>
    <v-card flat class="pb-0 mb-0 pt-0">
      <v-card-text class="pb-0 mb-0 px-0 mt-0 pt-2">
        <div>
          <v-toolbar flat>
              <v-toolbar-title class="ml-2 title">
                Analytics
              </v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-col cols="auto" class="mr-auto">
                <v-select
                  v-model="selectedResourceId"
                  label="Selected Resource"
                  :items="resources"
                  item-text="title"
                  item-value="id"
                  :menu-props="{ offsetY: true }"
                  outlined
                  dense
                  clearable
                  hide-details="auto">
                </v-select>
              </v-col>
            </v-toolbar>
        </div>
        <v-divider></v-divider>
        <MetabaseEmbed
          v-show="currentPath"
          :path="currentPath"
          :fitHeight="fitHeight">
        </MetabaseEmbed>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import MetabaseEmbed from '@/components/MetabaseEmbed.vue'
import MetabaseApi from '@/axios/metabase'
import { metabaseMixin } from '@/mixins/metabase'
export default {
  data () {
    return {
      currentPath: null,
      selectedResourceId: null
    }
  },
  name: 'Analytics',
  mixins: [metabaseMixin],
  components: { MetabaseEmbed },
  watch: {
    selectedResourceId: {
      handler (newValue) {
        if (newValue && typeof newValue === 'number') {
          this.getEmbedUrl()
        }
      }
    }
  },
  async created () {
    this.offsetHeight = 175
    await this.getEmbedUrl()
  },
  computed: {
    resources () {
      return this.$store.getters.resources.filter(r => !r.link)
    },
    currentResource () {
      if (this.selectedResourceId) {
        return this.resources.find(r => r.id === this.selectedResourceId)
      }
      return null
    }
  },
  methods: {
    async getEmbedUrl () {
      if (this.currentResource) {
        const { resource, id } = this.currentResource
        const embedUrl = await MetabaseApi.getSignedEmbedUrl(resource, id)
        this.currentPath = embedUrl
      }
    }
  }
}
</script>